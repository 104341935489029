<template>
    <div style="margin-top:30px">
      <b-overlay :show="showLoading" no-wrap />

      <b-popover      container="lg-popover"
                      target="my_popover"
                      placement="buttom"
                      triggers="hover"
      >
        <template #title>
          Vente à distance - Obligations relatives au démarchage téléphonique en assurances
        </template>

        <p>Dans le cadre de votre mission, vous vous engagez &agrave; respecter l&rsquo;ensemble des r&egrave;gles relatives au d&eacute;marchage t&eacute;l&eacute;phonique, y compris la loi n&deg;2021-402 du 8 avril 2021, le d&eacute;cret n&deg;2022-34 du 17 janvier 2022 et le d&eacute;cret n&deg;2022-1313 du 13 octobre 2022.</p>
        <p><u>Vos obligations </u>:</p>
        <ul>
          <li>Mettre en place une vente en deux temps lorsque vous d&eacute;marchez par t&eacute;l&eacute;phone un prospect qui n&rsquo;est pas client ou qui n&rsquo;a pas sollicit&eacute; votre appel de mani&egrave;re active.</li>
          <li>Informer le prospect d&egrave;s le d&eacute;but de l&rsquo;appel que celui-ci est enregistr&eacute; et qu&rsquo;il peut obtenir une copie de cet enregistrement s&rsquo;il en fait la demande.</li>
          <li>Mettre fin &agrave; l&rsquo;appel si le prospect refuse l&rsquo;enregistrement.</li>
          <li>Vous vous engagez &agrave; enregistrer l&rsquo;ensemble des conversations t&eacute;l&eacute;phoniques qui auront amen&eacute; &agrave; la conclusion du contrat d&rsquo;assurance et &agrave; les conserver.&nbsp;</li>
          <li>La signature &eacute;lectronique ne pourra intervenir lors du deuxi&egrave;me appel et vous ne pourrez pas l&rsquo;accompagner durant ce processus de signature.</li>
          <li>S&rsquo;assurer de permettre la tra&ccedil;abilit&eacute; et l&rsquo;int&eacute;grit&eacute; de l&rsquo;ensemble des appels t&eacute;l&eacute;phoniques.</li>
          <li>Respecter strictement le d&eacute;cret du 13 octobre 2022 encadrant les jours, horaires et fr&eacute;quence d&rsquo;appels quand celui-ci s&rsquo;applique. Les appels dans ce cas devront &ecirc;tre pass&eacute;s entre 10h et 13h et entre 14h &agrave; 20h du lundi au vendredi. Ils sont en revanche interdits les samedis, dimanches et jours f&eacute;ri&eacute;s. Cet encadrement s&#39;applique aussi bien aux personnes non inscrites sur la liste Bloctel qu&#39;&agrave; celles inscrites mais sollicit&eacute;es dans le cadre d&#39;un contrat en cours.</li>
          <li>Limitation stricte de l&#39;acc&egrave;s aux enregistrements aux seuls agents de l&#39;Autorit&eacute; de contr&ocirc;le prudentiel et de r&eacute;solution et aux agents mentionn&eacute;s aux articles L. 511-3 et L. 511-21 du code de la consommation.</li>
        </ul>
        <p><u>Destruction des enregistrements</u> :</p>
        <ul>
          <li>Sans d&eacute;lai, lorsque le souscripteur ou l&#39;adh&eacute;rent &eacute;ventuel s&#39;est explicitement oppos&eacute; &agrave; la poursuite de la communication t&eacute;l&eacute;phonique ou &agrave; la proposition commerciale</li>
          <li>En l&#39;absence de r&eacute;ponse favorable &agrave; une proposition commerciale, dans un d&eacute;lai d&#39;un mois &agrave; compter de la date de cette proposition (le souscripteur ou l&#39;adh&eacute;rent &eacute;ventuel manifestant ainsi une absence d&#39;int&eacute;r&ecirc;t ou son souhait de ne pas donner suite &agrave; cette proposition)</li>
        </ul>
      </b-popover>
        <b-modal
          id="modal-enfant"
          ref="my-modal"
          cancel-variant="outline-secondary"
          ok-title="Enregistrer"
          cancel-title="fermer"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
          centered
          title="Ajouter un enfant"
        >
          <b-form @submit.stop.prevent="handleSubmit" >
            <b-form-group>
              <label for="nom">Nom :</label>
              <b-form-input
                id="nom"
                v-model="enfant.nom"
                type="text"
                placeholder="Nom"
                class="text-capitalize"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!$v.enfant.nom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
            <b-form-group>
              <label for="prenom">Prenom :</label>
              <b-form-input 
                id="prenom"
                v-model="enfant.prenom"
                type="text"
                placeholder="Prénom"
                class="text-capitalize"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!$v.enfant.prenom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
            <b-form-group>
              <label for="date_naissance">Date de naissance :</label>
              <flat-pickr
                class="form-control"
                v-model="enfant.date_naissance"
                id="date-naissance"
                :config="config"
                placeholder="Date de naissance"
                data-max-date="today"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!$v.enfant.date_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-form>
        </b-modal>

        <!-- <b-tab title="Assuré principal" variant="text-left text-uppercase"> -->

        <!-- <b-row  v-if="objetSante.enfantCharge>0">
          <b-col md="6" sm="12">
            <h4 class="title-custom-wizard mt-2"><u>Enfants</u></h4>
          </b-col>
          <b-col md="6" sm="12">
            <div class="col-auto" v-if="enfantsLocal.length<8">
              <b-button
                v-b-modal.modal-enfant
                variant="outline-primary"
                block
              >
              <feather-icon 
                icon="PlusIcon"
                class="mr-50"
              />
             
              </b-button>
            </div>
          </b-col>
        </b-row> -->
      <b-row class="mb-2">
        <b-col md="12"><b-form-group id="lg-popover">
          <label class="font-weight-normal" style="font-size: 1rem;">Cette souscription s'effectue-t-elle dans le cadre d'une vente à distance ? <span
              class="d-inline-block"
              variant="outline-primary"
              id="my_popover"
          ><feather-icon
              icon="InfoIcon"
              class=" cursor-pointer"
              size="21" /></span
          ></label>
          <b-form-radio-group
              label=""
              name="isadistanceValue"
              v-model="devis.is_a_distance"
              :options="BooleanValue1"
          ></b-form-radio-group>

        </b-form-group>
        </b-col>
      </b-row>
      <div class="mb-2" v-if="devis.is_a_distance">
        <label class="d-block">De quel type de vente s’agit-il ?</label>
        <div role="radiogroup" class="demo-inline-spacing bv-no-focus-ring" id="__BVID__852" style="margin-bottom:10px">
          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" name="type-vente" v-model="devis.type_vente" class="custom-control-input" value="optin" id="__BVID__852_BV_option_0">
            <label class="custom-control-label" for="__BVID__852_BV_option_0">
              <span>Vente à distance en 1 temps </span>
              <span
                  v-b-popover.hover.top="'Prospect ayant préalablement consenti à l’appel ou client.'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  title="Vente à distance en 1 temps"
                  class="ml-1"
                  variant="outline-primary"
              >
               <feather-icon
                   icon="InfoIcon"
                   size="20"
               />
              </span>
            </label>
          </div>
          <div class="custom-control custom-control-inline custom-radio">
            <input type="radio" name="type-vente" v-model="devis.type_vente" class="custom-control-input" value="optout" id="__BVID__852_BV_option_1">
            <label class="custom-control-label" for="__BVID__852_BV_option_1">
              <span>Vente à distance en 2 temps </span>
              <span
                  v-b-popover.hover.top="'Prospect n’ayant pas consenti à l’appel. Un délai de 24h s’appliquera avant la mise en place de la signature électronique. .'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  title="Vente à distance en 2 temps"
                  class="ml-1"
                  variant="outline-primary"
              >
               <feather-icon
                   icon="InfoIcon"
                   size="20"
               />
              </span>
            </label>
          </div>
        </div>
      </div>

      <b-row>

          <b-col>
            <b-form-group>
              <label for="date-effet" class="font-weight-normal">Date d'effet *</label>
              <span
                v-b-popover.top.hover.html="`<p><strong>ECA :</strong> A partir du 16 du mois, la date d'effet minimum doit être le 1er jour du mois suivant.</p>`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Date d'effet" class="ml-1 cursor-pointer"
                variant="outline-primary">
                <feather-icon icon="InfoIcon" size="17" />
              </span>
              <flat-pickr
                class="form-control"
                v-model="objetSante.date_effet"
                id="date-effet"
                :config="configDateEffet"
                aria-autocomplete="off"
                placeholder="date d'effet"
              />
              <b-form-invalid-feedback :state="!$v.objetSante.date_effet.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        
          <b-col>
            <b-form-group
              label="Département du siège social de l'entreprise *"
              label-for="departement"
            >
              <cleave
                id="departement"
                v-model="objetSante.departement"
                type="text"
                placeholder="Département de l'entreprise"
                class="form-control"
                :raw="false"
                :options="options.departement"
              />
              <b-form-invalid-feedback :state="!$v.objetSante.departement.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
       
          <b-col md="2">
            <b-form-group label="Conjoint à assurer ?">
              <b-form-radio-group
                label="Option conjoint"
                id="conjoint_option"
                v-model="objetSante.conjoint"
                :options="BooleanValue"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          
              <b-col >
                <b-form-group label="Nombre d'enfants à assurer *" label-for="enfant">
                  <b-form-input
                    id="enfant"
                    type="number"
                    v-model="objetSante.enfantCharge"
                    placeholder="Nombre d'enfants"
                  />
                  <b-form-invalid-feedback :state="!$v.objetSante.enfantCharge.$error">
                    <span v-if="!$v.objetSante.enfantCharge.required">Veuillez renseigner le(s) champ(s) obligatoire(s)</span>
                    <span v-if="!$v.objetSante.enfantCharge.between" class="error">Veuillez renseigner une valeur entre 0 et 8</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            
              <b-col>
                <b-form-group label="Le proposant est-il éligible au cadre du RIA ?">
                  <b-form-radio-group
                    label="Le proposant est-il éligible au cadre du RIA?"
                    id="ria"
                    v-model="objetSante.ria"
                    :options="BooleanValue"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>

          <b-col>
            <b-form-group label="Le client souhaite-t-il bénéficier de la loi Madelin ?">
              <b-form-radio-group
                label="Le client souhaite-t-il bénéficier de la loi Madelin ?"
                v-model="devis.loi_madelin"
                name="madelinValue"
                :options="BooleanValue1"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row> 
        
        <b-row>
          <b-col md="12" sm="12">
            <h4 class="title-custom-wizard"><u>Assure principal (signataire)</u></h4>
          </b-col>
        </b-row>

        <b-row>
          <!-- Civilite -->
          <b-col md="3">
            <b-form-group label="Civilité *" label-for="assure_civilite">
                <b-form-select v-model="prospect.civilite" :options="civilite" id="assure_civilite" />
                <b-form-invalid-feedback :state="!$v.prospect.civilite.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>            
            </b-form-group>
          </b-col>
          <!-- Nom -->
          <b-col md="3">
            <b-form-group label="Nom *" label-for="assure_nom">
              <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.nom }}</div>
              <b-form-input v-else
                id="nom"
                v-model="prospect.nom"
                type="text"
                placeholder="Nom"
                autocomplete="nope"
                class="text-capitalize"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!$v.prospect.nom.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-col>
          <!-- Prénom -->
          <b-col md="3">
            <b-form-group label="Prénom *" label-for="assure_prenom">
              <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.prenom }}</div>

              <b-form-input v-else
                id="nom"
                v-model="prospect.prenom"
                type="text"
                placeholder="Prénom"
                autocomplete="nope"
                class="text-capitalize"
              />
              <b-form-invalid-feedback :state="!$v.prospect.prenom.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Date de naissance -->
          <b-col md="3">
            <b-form-group label="Date de naissance *" label-for="date-naissance">
              <div class="form-control" readonly disabled v-if="prospect.statut_gda==='VERT' || prospect.statut_gda==='ROUGE' || prospect.statut_gda==='ORANGE' ">{{ prospect.date_naissance }}</div>

              <flat-pickr  v-else
                class="form-control"
                v-model="prospect.date_naissance"
                id="date-naissance"
                :config="config"
                placeholder="Date de naissance"
                data-max-date="today"
              />
              <b-form-invalid-feedback :state="!$v.prospect.date_naissance.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null"> {{ errorDateNaissanceProspect }} </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Nom de naissance -->
          <b-col md="3">
            <b-form-group label="Nom de naissance *" label-for="assure-nom-naissance">
                <b-form-input v-model="prospect.nom_naissance" id="assure-nom-naissance" class="text-capitalize" placeholder="Nom de naissance" type="text" />
                <b-form-invalid-feedback :state="!$v.prospect.nom_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>      
            </b-form-group>
          </b-col>
          <!-- Code postal de naissance -->
          <b-col md="3">
            <b-form-group label="Code postal de naissance *" label-for="assure-code-postal-naissance">
                <b-form-input id="assure-code-postal-naissance" v-model="prospect.code_postal_naissance" type="text" :raw="false" placeholder="Code postal de naissance" />
                <b-form-invalid-feedback :state="!$v.prospect.code_postal_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>  
            </b-form-group>
          </b-col>
          <!-- Commune de naissance -->
          <b-col md="3">
            <b-form-group label="Commune de naissance *" label-for="assure-commune-naissance">
                <b-form-input v-model="prospect.commune_naissance" class="text-capitalize" id="assure-commune-naissance" placeholder="Commune de naissance" type="text" />
                <b-form-invalid-feedback :state="!$v.prospect.commune_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Pays de naissance -->
          <b-col md="3">
              <b-form-group label="Pays de naissance *" label-for="assure-pays-naissance">
                <v-select v-model="prospect.pays_naissance" id="assure-pays-naissance" :close-on-select="true" :reduce="pays => pays.pays" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays" class="flex-grow-1" input-id="pays" label="pays" placeholder="Pays de naissance">
                  <template #option="{ pays }">
                    <span class="ml-50"> {{ pays }}</span>
                  </template>

                  <template #selected-option="{ pays }">
                    <span class="ml-50"> {{ pays }}</span>
                  </template>
                  <div slot="no-options">Aucune pays disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="!$v.prospect.pays_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-1">

            <b-col md="3">
            <b-form-group label="Régime de sécurité sociale *" label-for="regime">
              <b-form-select v-model="prospect.regime_obligatoire" :options="regimeOptions" />
                <b-form-invalid-feedback :state="!$v.prospect.regime_obligatoire.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>       
            </b-form-group>
          </b-col>
          <!-- Code postal -->
          <b-col md="3">
            <b-form-group label="Code postal *" label-for="code postal">
                <cleave id="code-postal" v-model="prospect.moyen_contact.code_postal" :options="options.codePostal" :raw="false" class="form-control" placeholder="Code postal" type="number" />
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.code_postal.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Ville -->
          <b-col md="3">
            <b-form-group label="Ville *" label-for="ville">
                <b-form-select v-model="prospect.moyen_contact.ville_id">
                  <b-form-select-option :value="null">Choisissez</b-form-select-option>
                  <b-form-select-option v-for="ville in villeOptions" :value="ville.id" :key="ville.id">{{ ville.ville }}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="!$v.prospect.moyen_contact.ville_id.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- IsPPE -->
        </b-row>

        <b-alert
          v-show="objetSante.ria"
          variant="danger"
          show
          class="mb-2"
        >
          <div class="alert-body">
            <ul class="list-unstyled my-0">
              <li>Attention : la Résiliation Infra Annuelle (RIA) ne concerne que les contrats souscrits depuis plus d'un an.</li>
              <li> La date d'effet du nouveau contrat doit être au plus tôt le {{ riaDate }} soit 35 jours <strong>(+24h en cas de vente en 2 temps)</strong> à compter d'aujourd'hui.</li>
              <li>Si le contrat a moins d'un an, l’assuré doit procéder lui même à la résiliation de son contrat à l'échéance anniversaire, en respectant les délais de résiliation prévus au contrat.</li>
              <li>Merci de vérifier et modifier le cas échéant l’option et la date renseignées ci-dessus.</li>
            </ul>
          </div>
        </b-alert>
        <b-row>
          <b-col md="12">
            <b-form-group label="L'assuré, ou une ou des personnes de son entourage étroitement associées, exercent-ils ou ont-ils cessé d'exercer depuis moins d'un an des fonctions politiques, juridictionnelles ou administratives ? (pour plus de détails, voir l'onglet FAQ)">  
                <b-form-radio-group id="ppe_2_option_sante_tns" v-model="prospect.isPPE" :options="BooleanValue" label="prospect à assurer"></b-form-radio-group>
                <b-form-invalid-feedback :state="!$v.prospect.isPPE.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="objetSante.conjoint" class="">
          <b-col md="12" sm="12">
            <h4 class="title-custom-wizard mt-2"><u>Conjoint</u></h4>
          </b-col>
          <!-- Civilité -->
          <b-col md="3">
            <b-form-group label="Civilité *" label-for="conjoint-civilite">
                <b-form-select v-model="conjoint.civilite" :options="civilite" id="conjoint-civilite" />
                <b-form-invalid-feedback :state="!$v.conjoint.civilite.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Nom -->
          <b-col md="3">
            <b-form-group label="Nom *" label-for="nom">
              <div class="form-control" readonly disabled v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' ">{{ conjoint.nom }}</div>
              <b-form-input v-else
                id="nom"
                v-model="conjoint.nom"
                autocomplete="nope"
                type="text"
                placeholder="Nom"
                class="text-capitalize"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!$v.conjoint.nom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-col>
          <!-- Prénom -->
          <b-col md="3">
            <b-form-group  label="Prénom *" label-for="prenom">
              <div class="form-control" readonly disabled v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' ">{{ conjoint.prenom }}</div>
              <b-form-input v-else
                id="nom"
                v-model="conjoint.prenom"
                autocomplete="nope"
                type="text"
                placeholder="Prénom"
                class="text-capitalize"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!$v.conjoint.prenom.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
          </b-col>
          <!-- Date de naissance -->
          <b-col md="3">
            <b-form-group label="Date de naissance *" label-for="date_naissance">
              <div class="form-control" readonly disabled v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' ">{{ conjoint.date_naissance }}</div>

              <flat-pickr v-else
                class="form-control"
                v-model="conjoint.date_naissance"
                id="date-naissance"
                :config="config"
                placeholder="Date de naissance"
                data-max-date="today"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!$v.conjoint.date_naissance.$error">
              Veuillez renseigner le(s) champ(s) obligatoire(s)
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="errorDateNaissanceConjoint ? false : null"> {{ errorDateNaissanceConjoint }} </b-form-invalid-feedback>
          </b-col>
            <!-- Nom de naissance -->
            <b-col md="3">
              <b-form-group label="Nom de naissance *" label-for="conjoint_nom_naissance">
                  <b-form-input v-model="conjoint.nom_naissance" id="conjoint_nom_naissance" class="text-capitalize" placeholder="Nom de naissance" type="text" />
                  <b-form-invalid-feedback :state="!$v.conjoint.nom_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <!-- Code postal de naissance -->
            <b-col md="3">
              <b-form-group label="Code postal de naissance *" label-for="conjoint-code-postal-naissance">
                  <b-form-input id="conjoint-code-postal-naissance" v-model="conjoint.code_postal_naissance" type="text" :raw="false" placeholder="Code postal de naissance" />
                  <b-form-invalid-feedback :state="!$v.conjoint.code_postal_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
               </b-form-group>
            </b-col>
            <!-- Commune de naissance -->
            <b-col md="3">
              <b-form-group label="Commune de naissance *" label-for="conjoint-commune-naissance">
                  <b-form-input v-model="conjoint.commune_naissance" class="text-capitalize" id="conjoint-commune-naissance" placeholder="Commune de naissance" type="text" />
                    <b-form-invalid-feedback :state="!$v.conjoint.commune_naissance.$error">
                      Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                  </b-form-group>
            </b-col>
            <!-- Pays de naissance -->
            <b-col md="3">
                <b-form-group label="Pays de naissance *" label-for="pays-naissance">
                  <v-select v-model="conjoint.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays" class="flex-grow-1" input-id="pays" label="pays" placeholder="Pays de naissance">
                    <template #option="{ pays }">
                      <span class="ml-50"> {{ pays }}</span>
                    </template>

                    <template #selected-option="{ pays }">
                      <span class="ml-50"> {{ pays }}</span>
                    </template>
                    <div slot="no-options">Aucune pays disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="!$v.conjoint.pays_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
            <!-- IsPPE -->
            <b-col md="12">
              <b-form-group label="Le conjoint, ou une ou des personnes de son entourage étroitement associées, exercent-ils ou ont-ils cessé d'exercer depuis moins d'un an des fonctions politiques, juridictionnelles ou administratives ? (pour plus de détails, voir l'onglet FAQ)">
                  <b-form-radio-group id="ppe_2_option_sante_seniors" v-model="conjoint.isPPE" :options="BooleanValue" label="Conjoint à assurer"></b-form-radio-group>
                  <b-form-invalid-feedback :state="!$v.conjoint.isPPE.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
              </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="objetSante.enfantCharge>0">
          <b-col md="12" sm="12">
            <h4 class="title-custom-wizard mt-2"><u>Enfants</u></h4>
          </b-col>
        </b-row>

        <!-- <b-row v-if="objetSante.enfantCharge>0 && enfantsLocal.length>0 ">
          <div class="col-12 mb-1">
            <span style="font-size:14px">Sélectionnez les enfants à assurer : </span>
          </div>
          <div class="col-12 mb-1" v-for="(enf,index) in enfantsLocal" :key="index">
            <b-row>
              <b-col md="1" class="d-inline text-center" style="margin-top:30px">
                <b-form-group class="d-inline">
                  <b-form-checkbox
                    class="d-inline"
                    v-model="enf.state"
                    :disabled="!hasPerToCheck && !enf.state"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group>
                  <label for="nom">Nom :</label>
                  <b-form-input
                    id="nom"
                    :value="enf.nom"
                    type="text"
                    disabled
                    placeholder="Prénom"
                    class="text-capitalize"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group>
                  <label for="nom">Prénom :</label>
                  <b-form-input
                    id="nom"
                    :value="enf.prenom"
                    type="text"
                    disabled
                    placeholder="Prénom"
                    class="text-capitalize"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group>
                  <label for="date_naissance">Date de naissance :</label>
                  <flat-pickr
                    class="form-control"
                    :value="enf.date_naissance"
                    id="date-naissance"
                    disabled
                    :config="config"
                    placeholder="Date de naissance"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2" class="text-center" style="margin-top:30px">
                <div class="d-inline" @click="editEnfant(enf,index)">
                  <feather-icon  v-b-modal.modal-enfant icon="EditIcon" size="22" class="mr-50" />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-row> -->

        <div v-if="objetSante.enfantCharge>0">
          <b-row>
            <div class="col-12 mb-1">
              <span style="font-size:14px">Sélectionnez les enfants à assurer : </span>
            </div>
          </b-row>
          <b-row v-for="(enf,index) in enfantsArray" :key="index">
            <b-col md="1" class="d-inline text-center" style="margin-top:30px">
              <b-form-group class="d-inline">
                <b-form-checkbox
                  v-model="enf.state"
                  :disabled="!hasPerToCheck && !enf.state"
                  class="d-inline"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group>
                <label for="nom">Nom :</label>
                <div class="form-control" readonly disabled v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' ">{{ enf.nom }}</div>

                <b-form-input v-else
                  id="nom"
                  v-model="enf.nom"
                  autocomplete="nope"
                  type="text"
                  placeholder="Nom"
                  class="text-capitalize"
                />
                <b-form-invalid-feedback :state="!$v.enfantsArray.$each[index].nom.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group>
                <label for="nom">Prénom :</label>
                <div class="form-control" readonly disabled v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' ">{{ enf.prenom }}</div>

                <b-form-input v-else
                  id="nom"
                  v-model="enf.prenom"
                  autocomplete="nope"
                  type="text"
                  placeholder="Prénom"
                  class="text-capitalize"
                />
                <b-form-invalid-feedback :state="!$v.enfantsArray.$each[index].prenom.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group>
                <label for="date_naissance">Date de naissance :</label>
                <div class="form-control" readonly disabled v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' ">{{ enf.date_naissance }}</div>

                <flat-pickr v-else
                  class="form-control"
                  v-model="enf.date_naissance"
                  id="date-naissance"
                  :config="config"
                  placeholder="Date de naissance"
                  data-max-date="today"
                />
                <b-form-invalid-feedback :state="!$v.enfantsArray.$each[index].date_naissance.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col md="2" class="text-center" style="margin-top:30px" v-if="!enf.id">
              <div class="d-inline" @click="removeEnfant(index)">
                <feather-icon   icon="XIcon" size="22" class="mr-50" />
              </div>
            </b-col>
          </b-row>
        </div>


        <!-- <b-row v-if="objetSante.enfantCharge>0">
          <b-col md="12" sm="12">
            <b-button
                @click="indexEnfant=null"
                v-b-modal.modal-enfant
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
            >
              <feather-icon icon="PlusIcon"/>
              <span class="align-middle">Ajouter un Enfant</span>
            </b-button>
          </b-col>
        </b-row> -->

        <b-row v-if="objetSante.enfantCharge>0">
          <b-col md="12" sm="12">
            <b-button
                @click="addEnfant"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
            >
              <feather-icon icon="PlusIcon"/>
              <span class="align-middle">Ajouter un Enfant</span>
            </b-button>
          </b-col>
        </b-row>


        <!-- <b-row>
          <b-col md="12"> <h4 class="title-custom-wizard"><u>Reprise concurrence</u></h4></b-col>
          <b-col md="4">
            <label>Le proposant est-il éligible au cadre du RIA ?</label>
            <b-form-radio-group
              label="Le proposant est-il éligible au cadre du RIA?"
              id="ria"
              v-model="objetSante.ria"
              :options="BooleanValue"
            ></b-form-radio-group>
          </b-col>
        </b-row> -->

        <!-- </b-tab> -->

        <!-- <b-tab title="Structure Detail" variant="text-left text-uppercase"> -->
        <!-- <b-row class="mt-4">
          <b-col md="12"> <h4 class="title-custom-wizard"><u>Structure Detail</u></h4></b-col>
          <b-col md="3">
            <label>Le proposant est-il éligible au cadre du RIA ?</label>
            <b-form-radio-group
              label="Le proposant est-il éligible au cadre du RIA?"
              id="ria"
              v-model="objetSante.ria"
              :options="BooleanValue"
            ></b-form-radio-group>
          </b-col>
        </b-row> -->
        <!-- </b-tab> -->

        <!-- <b-tab title="Garanties/Exigence" variant="text-left text-uppercase"> -->
        <b-row class="mt-4">
          <b-col md="12">
            <h4 class="title-custom-wizard"><u>Garanties</u></h4>
          </b-col>
        </b-row>

        <b-row  v-for="(garantie, gr) in garanties.slice(0, 1)" :key="gr + 'garanties'">
          <b-col md="12" class="mt-2">
              <label>Quel est le besoin exprimé par le client ?</label>
              <div class="demo-inline-spacing mt-0 mb-2">
                <b-form-radio
                  v-for="(gn, cpt) in garantie.garantieniveaux" :key="cpt + 'garantiesNiveaux'" 
                  v-model="garantie.garantieNiveauID"
                  :value="[gn.id]"
                  class="custom-control-primary"
                >
                  <span>{{gn.garantie_niveau_libel}}</span>
                  <span
                    v-b-popover.hover.top="gn.garantie_details"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :title="gn.garantie_niveau_libel"
                    class="ml-1 cursor-pointer"
                    variant="outline-primary"
                  >
                  <feather-icon
                      icon="InfoIcon"
                      size="20"
                    />
                  </span>
                </b-form-radio>
              </div>
              <b-form-invalid-feedback :state="!hasSelectedGarantie">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
          </b-col>
        </b-row>


        <b-row class="mt-2">
          <b-col md="12">
            <label>Pourriez-vous préciser par garantie ?</label><br>
            <!-- small size -->
            <span style="font-size:10px">Attention à bien vérifier la cohérence avec le besoin exprimé par le client.</span>
          </b-col>
        </b-row>

        <b-row  >
          <b-col md="6" class="mt-2" v-for="(exigence, er) in exigences" :key="er + 'exigences'">
              <label>{{exigence.label}}</label>
              <div class="demo-inline-spacing mt-0 mb-2">
                <b-form-radio
                  v-for="(en, cpt) in exigence.exigenceniveaux" :key="cpt + 'exigencesNiveaux'" 
                  v-model="exigences[er].exigenceNiveauID"
                  :value="[en.id]"
                  class="custom-control-primary"
                >
                  {{titleClass(en.exigence_niveau_libel)}}
                </b-form-radio>
              </div>
            <b-form-invalid-feedback :state="!unselectedExigenceIds.includes(exigence.id)"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col lg="12">
            <b-button
              variant="primary"
              class="float-left"
              size="lg"
              @click="backStep"
            >
              Précédent
            </b-button>
            
            <b-button
              variant="primary"
              class="float-right"
              size="lg"
              @click="checkValidateField(0)"
            >
              Suivant
            </b-button>
          </b-col>
        </b-row>
    </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters } from "vuex";
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import {required,requiredIf,minLength,maxLength,between,email} from "vuelidate/lib/validators";
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import {
  BOverlay,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BFormSelectOption,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormValidFeedback,
  BAlert,
  VBTooltip,
  VBPopover,
  BPopover
} from "bootstrap-vue";

//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BPopover,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormValidFeedback,
    BOverlay,
    Cleave,
    BAlert,
    BFormSelectOption
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  
  computed: {
    ...mapGetters(["getEtude"]),
    ...mapGetters(["Tiers"]),
    ...mapGetters(["Conjoint"]),
    hasPerToCheck: function () {
      let enfantChecked = _.filter(this.enfantsArray, enf => enf.state);
      return this.objetSante.enfantCharge>enfantChecked.length
    },
    enfantsChecked: function () {
      let enfantChecked = _.filter(this.enfantsArray, enf => enf.state );
      return this.objetSante.enfantCharge==0 ? true : this.objetSante.enfantCharge==enfantChecked.length
    },
    enfantsHasValidDateNaissance: function () {
      let enfantNovalidDate = _.filter(this.enfantsArray, enf => enf.state && moment().diff(enf.date_naissance.split("/").reverse().join("-"), 'years',true)>25 );
      return this.objetSante.enfantCharge==0 || enfantNovalidDate.length==0 ? true : false
    },
  },
  watch: {
    'prospect.moyen_contact.code_postal': {
      immediate: true,
      handler(val) {
        if (val && val.length === 5) {
        this.$http
            .get(`/helpers/${val}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
                let obj = this.villeOptions.find(o => o.id === this.prospect.moyen_contact.ville_id);
                if(typeof obj === 'undefined' || obj === null)
                {
                  this.prospect.moyen_contact.ville_id = null
                }
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.villeOptions = []
        }
      }
    },
    // getEtude:function(val,old){
    //   alert('oke')
    //   console.log(val)
    // },
    'objetSante.ria': {
      handler(val) {
        if (val) {
          if (this.devis.type_vente == 'optout') {
            this.riaDate = moment().add(36, 'days').format('DD/MM/YYYY')
            this.changeDisabledDate(36)
          } else {
            this.riaDate = moment().add(35, 'days').format('DD/MM/YYYY')
            this.changeDisabledDate(35)
          }
        } else {
          this.changeDisabledDate(0)
        }
      }
    },
    'devis.type_vente': {
      handler(val) {
        if (this.objetSante.ria) {
          if (val == 'optout') {
            this.riaDate = moment().add(36, 'days').format('DD/MM/YYYY')
            this.changeDisabledDate(36)
          } else {
            this.riaDate = moment().add(35, 'days').format('DD/MM/YYYY')
            this.changeDisabledDate(35)
          }
        } else {
          this.changeDisabledDate(0)
        }
      }
    },
    'objetSante.date_effet':  {
      handler(val) {
        if(val) {
          this.checkDateFormat()
          if (!this.checkerDateEffet()) {
            this.objetSante.date_effet = null
          }
        }
      },
      deep: true,
      immediate: true,
    },
    getEtude: {
      handler: function (old, nld) {
        this.prospect                 =old.Tiers
        this.conjoint                 =old.Conjoint
        this.objetSante               =old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].objets.objet_sante_tns : null
        this.garanties                =old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].garanties : null
        this.exigences                =old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].exigences : null
        this.devis                    =old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].devis     : null
        this.enfantStore              =old.Enfant.Enfant
        console.log('************$$$$$$$ objet TNS objet **************** $$$$$$$$$$$$$$$$')
        console.log(old.itemsRisque.ItemsRisque[0].objets.objet_sante_tns)
        console.log('************$$$$$$$ objet TNS objet **************** $$$$$$$$$$$$$$$$')
        if(this.prospect.original_regime_obligatoire == null){
          this.prospect.original_regime_obligatoire = old.Tiers.regime_obligatoire;
        }
        if(this.prospect.regime_obligatoire != 'RÉGIME_SOCIAL'){
          this.prospect.regime_obligatoire = null
        }
      },
      deep:true,
      immediate:true
    },
    'devis.is_a_distance': {
      handler(val) {
        if (!val) {
          this.devis.type_vente = null
        }
      }
    },
    'objetSante.enfantCharge': {
      handler: function (old, nld) {
        if(old=='0'){
          this.enfantsArray = _.filter(this.enfantsArray, enf => enf.id);
        }
      },
      deep:true,
    },
    Tiers: {
      handler: function (old, nld) {
        this.prospect.original_nom = old.nom
        this.prospect.original_prenom = old.prenom
        this.prospect.original_date_naissance   =old.date_naissance
        this.prospect.moyen_contact.original_code_postal = old.moyen_contact.code_postal
      },
      immediate:true
    },
    Conjoint: {
      handler: function (old, nld) {
        this.conjoint.original_nom = old.nom
        this.conjoint.original_prenom = old.prenom
        this.conjoint.original_date_naissance   =old.date_naissance
      },
      immediate:true
    },
    enfantStore: {
      handler: function (old, nld) {
        this.$http
          .get(`devis/getProspectDevisEnfants/${this.devis.id}`)
          .then(r => {
            this.mapEnfantForStore(r.data)
          })
          .catch(e => {
            console.log(e)
          })
      },
      immediate:true
    },
  },
  data() {
    return {
      villeOptions: [],
      pays: [],
      civilite: [
        { value: null, text: 'Choisissez' },
        { value: 'MR', text: 'M.' },
        { value: 'MME', text: 'Mme' }
      ],
      BooleanValue1: [
        { value: 1, text: "Oui" },
        { value: 0, text: "Non" },
      ],
      regimeOptions: [
        {
          value: null,
          text: 'Choisissez',
        },
        {
          value: 'RÉGIME_SOCIAL',
          text: 'Régime des travailleurs non salariés et non agricoles (ex RSI)'
        },

      ],
      errorDateNaissanceProspect: null,
      errorDateNaissanceConjoint: null,
      objetSante:{
        date_effet:null,
        departement:null,
        enfantCharge:null,
      },
      prospect:{
        nom: null,
        prenom: null,
        date_naissance:null,
        original_regime_obligatoire:null,
      },
      enfant:{
        id:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        state:false,
        type_lien_familial:'ENFANT',
      },
      conjoint:{
        id:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        civilite:null,
        num_secu:null,
        numeroOrganisme:null,
        type_lien_familial:'CONJOINT',
      },
      garanties:[],
      exigences:[],
      BooleanValue: [
        { value: 1, text: "Oui" },
        { value: 0, text: "Non" },
      ],
      configDateNaissance: {
          dateFormat: 'd/m/Y',
          disable:[{from:moment(new Date(), "DD/MM/YYYY").subtract(18, 'years')._d,to:'25/02/3000'}],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
      },
      configDateEffet: {
          dateFormat: 'd/m/Y',
          // disable:[{from:moment(new Date(), "DD/MM/YYYY"),to:'25/02/3000'}],
          disable: [ function(date) {
              let localDay=new Date()
              let firstDayCurrentMonth = new Date(localDay.getFullYear(), localDay.getMonth(), 1)
              return date < firstDayCurrentMonth
              // return date < localDay.setDate(localDay.getDate()-1)
          },
          function (date) {
            let dateMaximum=new Date()
            dateMaximum.setHours(0, 0, 0, 0)
            let currentMonth  = dateMaximum.getMonth()
            dateMaximum.setFullYear(currentMonth > 10 ? dateMaximum.getFullYear() + 1 : dateMaximum.getFullYear(), 11, 31) 
            return date > dateMaximum
          }],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
      },
      config: {
        dateFormat: "d/m/Y",
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },
      },
      hasSelectedGarantie:false,
      unselectedExigenceIds: [],
      showLoading:false,
      enfantsLocal:[],
      enfantStore:[],
      updatedEnfant:true,
      devis:null,
      options: {
        departement: {
          blocks: [3],
        },
        codePostal: {
          blocks: [5]
        }
      },
      indexEnfant:null,
      riaDate:null,
      enfantField:{
        id:null,
        nom:null,
        prenom:null,
        state:null
      },
      enfantsArray:[]
    };
  },
  validations: {
    objetSante: { 
      date_effet:  { required },
      departement: { required, minLength: minLength(2),maxLength: maxLength(3)},
      enfantCharge:{ required,between: between(0,8) }
    },
    prospect: {
        civilite: {required},
        nom: {required},
        prenom: {required}, 
        date_naissance:{ required},
        nom_naissance :{ required },
        code_postal_naissance :{ required },
        commune_naissance :{ required },
        pays_naissance :{ required },
        regime_obligatoire :{ required },
        moyen_contact: {
          code_postal: { required },
          ville_id:{ required }
        },
        isPPE: {required},
    },
    enfant: { 
        nom:{ required },
        prenom:{ required },
        date_naissance:{ required },
    },
    conjoint: {
        civilite: {required},
        nom:{ required },
        prenom:{ required },
        date_naissance:{ required },
        nom_naissance :{ required },
        code_postal_naissance :{ required },
        commune_naissance :{ required },
        pays_naissance :{ required },
        isPPE: {required},
    },
    enfantsArray: {
      $each: {
        nom: {
          required
        },
        prenom: {
          required
        },
        date_naissance: {
          required
        },
      }
    }
  },
  created() {
    this.getPays()
  },
  mounted() {
    this.$v.$reset();
    this.$v.objetSante.$touch();
    this.$v.conjoint.$touch();
    this.$v.prospect.$touch();
    this.$v.enfantsArray.$touch();
    if ((this.objetSante.ria && moment(this.objetSante.date_effet,'DD/MM/YYYY') < moment(this.riaDate,'DD/MM/YYYY')) ||
    (!this.objetSante.ria && moment(this.objetSante.date_effet,'DD/MM/YYYY') < moment(moment(),'DD/MM/YYYY'))) {
      
      if (this.$v.objetSante.$error || this.$v.prospect.$error ||  (this.objetSante.conjoint && this.$v.conjoint.$error  ) || (this.objetSante.enfantCharge && this.objetSante.enfantCharge > 0 && this.$v.enfantsArray.$error) ) {
          return 0;
      }
    }
    
  },
  methods: {
    changeDisabledDate(day) {
      this.configDateEffet.disable = [ 
        function(date) {
            let localDay=new Date()
            localDay.setHours(0, 0, 0, 0)
            localDay.setDate(localDay.getDate() + day)
            let dateCompare = day == 0 ? new Date(localDay.getFullYear(), localDay.getMonth(), 1) : localDay
            return date < dateCompare
        },
        function (date) {
          let dateMaximum=new Date()
          dateMaximum.setHours(0, 0, 0, 0)
          let currentMonth  = dateMaximum.getMonth()
          dateMaximum.setFullYear(currentMonth > 10 ? dateMaximum.getFullYear() + 1 : dateMaximum.getFullYear(), 11, 31) 
          return date > dateMaximum
        }
      ]
    },
    checkerDateEffet() {
      const dateDuJour = moment().format('YYYY-MM-DD')
      const firstDayCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
      const effetDate = moment(this.objetSante.date_effet, 'DD/MM/YYYY').format('YYYY-MM-DD')
      let dateMaximum = moment() // .add(9, 'months').format('YYYY-MM-DD')
      if(dateMaximum.month() > 10)
        dateMaximum.add(1, 'years').format('YYYY-MM-DD')
      else dateMaximum.endOf('year').format('YYYY-MM-DD')

      if (this.objetSante.ria) {
        let riaDateLocal = moment().add(35, 'days').format('YYYY-MM-DD')
        this.changeDisabledDate(35)
        if (this.devis.type_vente == 'optout') {
          riaDateLocal = moment().add(36, 'days').format('YYYY-MM-DD')
          this.changeDisabledDate(36)
        } else {
          riaDateLocal = moment().add(35, 'days').format('YYYY-MM-DD')
          this.changeDisabledDate(35)
        }

        if (!moment(effetDate).isBetween(riaDateLocal, dateMaximum, null, '[]')) {
          return false
        }
      } else {
        this.changeDisabledDate(0)
        if (!moment(effetDate).isBetween(firstDayCurrentMonth, dateMaximum, null, '[]')) {
          return false
        }
      }
      return true
    },
    checkDateFormat() {
      const format = 'YYYY-MM-DD'
      const date = moment(this.objetSante.date_effet, format, true)
      if (date.isValid()) {
        this.objetSante.date_effet = date.format('DD/MM/YYYY')
      } else {
        this.objetSante.date_effet = moment(this.objetSante.date_effet, 'DD/MM/YYYY').format('DD/MM/YYYY')
      }
    },
    getPays() {
      this.$http
      .get(`tiers/getPays`)
      .then(r => {
        this.pays = r.data
      })
      .catch(e => {
        console.log(e)
      })
    },
    handleToastDanger(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
        title: text,
        icon: 'XIcon',
        variant: 'danger',
        },
      })
    },
    updateDevisLoiMadelin(){
          let dataMadelin={
              id:this.devis.id,
              loi_madelin:this.devis.loi_madelin
          }
          this.$http.post(`devis/updateDevisLoiMadelin`,dataMadelin).then((r) => {
          }).catch((err)=>{
              console.log(err)
          })
    },
   /* validationDateNaissance() {
      // Declaration
      const endDate = moment(this.objetSante.date_effet, 'DD/MM/YYYY').format('YYYY-MM-DD')
      const dateNaissanceAssurePrincipal = moment(this.prospect.date_naissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
      if (moment(endDate).diff(dateNaissanceAssurePrincipal, "years", true) < 65) 
      {
        // this.errorDateNaissanceProspect = null
      } else {
        // this.errorDateNaissanceProspect = "L'assuré principal ne doit pas avoir plus de 65 ans"
        this.handleToastDanger('L\'assuré principal ne doit pas avoir plus de 65 ans')
        return false
      }
      if (this.objetSante.conjoint == 1) {
        const dateNaissanceConjoint = moment(this.conjoint.date_naissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
        if (moment(endDate).diff(dateNaissanceConjoint, "years", true) < 65) {
          // this.errorDateNaissanceConjoint = null
        } else {
          // this.errorDateNaissanceConjoint = "Le conjoint ne doit pas avoir plus de 65 ans"
          this.handleToastDanger('Le conjoint ne doit pas avoir plus de 65 ans')
          return false
        }
      } else {
        this.errorDateNaissanceConjoint = null
      }
      return true
    },*/
    backStep(){
      this.$store.commit("setStepTarif", {
          step: {
            indexStep:0,
            active:'choix'
          },
      });
    },
    titleClass(word){
     return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    },
    mapEnfantForStore(enfants){
      this.enfantsArray=[]
      Object.values(this.enfantStore).forEach(enf => {
        let enfant={
          id:enf.lienable.id,
          civilite:enf.lienable.civilite,
          nom:enf.lienable.nom,
          prenom:enf.lienable.prenom,
          num_secu:enf.lienable.num_secu,
          numeroOrganisme:enf.lienable.numeroOrganisme,
          date_naissance:enf.lienable.date_naissance ? moment(enf.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
          state:false,
          original_date_naissance:enf.lienable.date_naissance ? moment(enf.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
          original_nom:enf.lienable.nom ,
          original_prenom:enf.lienable.prenom,
          statut_gda:enf.lienable.statut_gda
        }
        if(enfants && enfants.length>0){
          let checkExist=_.find(enfants, function(e) { return e.personne_physique_id == enf.lienable.id; });
          if(checkExist)
          enfant.state=true
        }
        this.enfantsArray.push(enfant)
      });
      // this.enfantStore.map((enf,key)=>{
      // })
    },
    resetModal() {
      this.enfant={
        id:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        state:false,
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      this.$v.$reset();
      this.$v.enfant.$touch();
      if (this.$v.enfant.$error) {
          return 0;
      }
      if(this.indexEnfant!=null){
        this.enfantsLocal[this.indexEnfant]=this.enfant
      }else{
        this.enfantsLocal.push(this.enfant)
      }
      this.resetModal()
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    checkEnfantValidateField(){
      let state=false;
      this.enfantsArray.map((enf)=>{

        if((enf.date_naissance!=enf.original_date_naissance && enf.original_date_naissance !='' && enf.original_date_naissance !=null)
         || (enf.nom!=enf.original_nom && enf.original_nom !='' && enf.original_nom != null)
         || (enf.prenom!=enf.original_prenom && enf.original_prenom !='' && enf.original_prenom != null) && enf.id ){
          state=true
          return state
        }
      })
      return state
    },

    async updateDevisTypeVente(value){

      this.showLoading=true
      let data={
        id:this.devis.id,
        type_vente:value,
        is_a_distance:this.devis.is_a_distance
      }
      await this.$http.post(`devis/updateDevisTypeVente`,data).then((r) => {
        this.showLoading=false
        return  true
      }).catch((err)=>{

        this.showLoading=false
        console.log(err)
      })
    },
    async checkValidateField(index){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.$v.$reset();
      this.$v.objetSante.$touch();
      this.$v.conjoint.$touch();
      this.$v.prospect.$touch();
      this.$v.enfantsArray.$touch();

      const typeVente         =this.devis.type_vente
      const is_a_distance     =this.devis.is_a_distance

      if(is_a_distance && typeVente==null){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Merci de Choisir le type du vente',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        return 0;
      }
      if (this.$v.objetSante.$error || this.$v.prospect.$error ||  (this.objetSante.conjoint && this.$v.conjoint.$error  ) || (this.objetSante.enfantCharge && this.objetSante.enfantCharge > 0 && this.$v.enfantsArray.$error) ) {
        return 0;
      }
      await this.updateDevisTypeVente(this.devis.type_vente)
      if(this.getEtude.itemsRisque.ItemsRisque[index].garanties[0].garantieNiveauID.length==0){
          this.hasSelectedGarantie=true
          return 0
      }else{
          this.hasSelectedGarantie=false
      }
      if(!this.enfantsChecked){
          this.handleToastDanger('Veuillez renseigner les informations relatives aux enfants à assurer. ')
          return 0
      }
      
      /*if(!this.enfantsHasValidDateNaissance){
        this.handleToastDanger('Vous ne pouvez pas sélectionner d\'enfants de 25 ans et plus, à la date du jour')
          return 0
      }*/
      if(this.objetSante.ria && !this.checkerDateEffet()){
        this.handleToastDanger('La date d’effet doit être postérieure au '+this.riaDate+' pour respecter le délai légal RIA.')
          return 0
      } else if (!this.objetSante.ria && !this.checkerDateEffet()) {
        return 0
      }
      if (this.objetSante.ria) {
        const effetDate = moment(this.objetSante.date_effet, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const riaDatemoment = moment(this.riaDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

        if (moment(effetDate).isBefore(moment(riaDatemoment))) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "La date d’effet doit être postérieure ou égale au " + this.riaDate + ' pour respecter le délai légal RIA',
              icon: 'XIcon',
              variant: 'danger'
            }
          })
          return 0
        }
      }

/*       if(this.prospect.date_naissance && moment().diff(moment(this.prospect.date_naissance,'DD-MM-YYYY'),'years',true) < 18  ){
        this.handleToastDanger('L’assuré principal doit être majeur')
          return 0
      } */

  /*     if(this.objetSante.conjoint && this.conjoint.date_naissance && moment().diff(moment(this.conjoint.date_naissance,'DD-MM-YYYY'),'years',true) < 18  ){
          this.handleToastDanger('Le conjoint doit être majeur')
          return 0
      } */
      this.unselectedExigenceIds = []
      this.getEtude.itemsRisque.ItemsRisque[index].exigences.forEach(element => {
        if (element.exigenceNiveauID.length == 0) {
          this.unselectedExigenceIds.push(element.id)
        }
      });

      if (this.unselectedExigenceIds.length > 0) {
        this.showLoading = false
        return 0
      }

      // const isValidDateNaissance = await this.validationDateNaissance()
      // if (!isValidDateNaissance) { this.showLoading = false; return 0 }


      let resultEnfant = await this.checkEnfantValidateField();
      // console.log("this.prospect.date_naissance : ");
      // console.log(this.prospect.date_naissance)
      // console.log("this.prospect.original_date_naissance : ");
      // console.log(this.prospect.original_date_naissance)
      if((this.prospect.date_naissance!=this.prospect.original_date_naissance && this.prospect.original_date_naissance!='' && this.prospect.original_date_naissance!=null) 
      || (this.conjoint.date_naissance!=this.conjoint.original_date_naissance && this.conjoint.original_date_naissance!='' && this.conjoint.original_date_naissance!=null) 
      || (this.prospect.nom!=this.prospect.original_nom && this.prospect.original_nom!='' && this.prospect.original_nom!=null)
      || (this.conjoint.nom!=this.conjoint.original_nom && this.conjoint.original_nom!='' && this.conjoint.original_nom!=null)
      || (this.prospect.prenom!=this.prospect.original_prenom && this.prospect.original_prenom!='' && this.prospect.original_prenom!=null)
      || (this.prospect.regime_obligatoire != this.prospect.original_regime_obligatoire)
      || (this.conjoint.prenom!=this.conjoint.original_prenom && this.conjoint.original_prenom!=''&& this.conjoint.original_prenom!=null)
      || (this.prospect.moyen_contact.code_postal!=this.prospect.moyen_contact.original_code_postal && this.prospect.moyen_contact.original_code_postal!='' && this.prospect.moyen_contact.original_code_postal!=null)
      || resultEnfant  )
      {
          this.$swal({
            html: `<p class="text-justify" style="font-size: 15px">Attention, vous souhaitez modifier une donnée potentiellement tarifante pour un devis non encore souscrit ou un contrat souscrit par l'intermédiaire de BubbleIn.
                  <br><br>
                  Les devis en cours d'étude seront modifiés avec ces nouvelles informations. Les devis déjà envoyés ou les contrats en cours de signature seront classés sans suite et il conviendra de les refaire. Si vous avez souscrit un contrat, il conviendra de vérifier la nécessité de procéder à un avenant.
                  <br><br>
                  Souhaitez-vous confirmer cette modification ?</p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
              html: 'text-left'
            },
            buttonsStyling: false
          })
          .then(confirmed => {
            if (confirmed.value) {
              this.updateDevisLoiMadelin()
              this.faireTarif(index)
              this.prospect.original_date_naissance  = this.prospect.date_naissance
              this.prospect.original_nom = this.prospect.nom
              this.prospect.original_prenom = this.prospect.prenom 
              this.conjoint.original_date_naissance = this.conjoint.date_naissance
              this.prospect.original_regime_obligatoire = this.prospect.regime_obligatoire
            }
          })
      }
      else {
        this.updateDevisLoiMadelin()
        this.faireTarif(index)
      }
      // console.log("this.prospect.date_naissance after faireTarif : ");
      // console.log(this.prospect.date_naissance)
      // console.log("this.prospect.original_date_naissance after faireTarif : ");
      // console.log(this.prospect.original_date_naissance)
    },
    async faireTarif(index) {

      this.showLoading=true
      this.getEtude.itemsRisque.ItemsRisque[index].objets.objet_sante_tns=this.objetSante
      this.getEtude.Tiers=this.prospect
      let chooseRisque= this.getEtude.itemsRisque.ItemsRisque[0]
      let risqueItem=this.getEtude
      risqueItem.Enfant.Enfant=this.objetSante.enfantCharge==0 ? [] : this.enfantsArray
      risqueItem.Enfant.enfantChecked = this.objetSante.enfantCharge==0 ? [] : _.filter(this.enfantsArray, enf => enf.state )
      risqueItem.itemsRisque.ItemsRisque=[]
      risqueItem.itemsRisque.ItemsRisque.push(chooseRisque)

      await this.persistObject(risqueItem)
        return new Promise((resolve, reject) => {
          resolve(true);
        });

      //return this.persistObject(risqueItem)

    },
    // openEnfant(){
    //   this.indexEnfant=null
    // },
    editEnfant(enfant,index){
      this.indexEnfant=index
      this.enfant={
        id:enfant.id,
        nom:enfant.nom,
        prenom:enfant.prenom,
        date_naissance:enfant.date_naissance
      }
    },
    async persistObject(risqueItem){
      try {
        await this.$http.post(`portefeuilles/addDevisPortefeuille`,risqueItem).then((response)=>{
          let enfants=response.data[1];
          let conjoint=response.data[2];
          let enfantChecked=response.data[5];
          this.$store.commit("setObjetEnfant", {
            Enfant: enfants,
          });
          this.$store.commit("setObjetConjoint", {
            Conjoint: conjoint,
          });
          return this.getTarifData(risqueItem,0,enfants,conjoint,enfantChecked)
        })
        //  console.log(this.prospect.original_date_naissance)


      } catch (e) {
          this.errors = e.response.data.errors;
      }
    },
    async getTarifData(risqueItem,risque,enfants,conjoint,enfantsChecked){
      try {
        const garantiesValues = this.getEtude.itemsRisque.ItemsRisque[risque].garanties
        garantiesValues.forEach((item, index) => {
          if (garantiesValues[index].garantieNiveauID.length <= 0) {
            this.getEtude.itemsRisque.ItemsRisque[risque].garanties[index].garantieNiveauID = []
            this.getEtude.itemsRisque.ItemsRisque[risque].garanties[index].garantieNiveauID.push(
              garantiesValues[index].garantieniveaux.find(item => item.garantie_niveau_libel == 'Non').id
            )
          }
        })
        risqueItem.itemsRisque.ItemsRisque[risque].garanties = this.getEtude.itemsRisque.ItemsRisque[risque].garanties
        let data={
          conjoint:conjoint,
          enfant:enfants,
          enfantChecked:enfantsChecked,
          assure:this.prospect,
          risque:{
            objet:risqueItem.itemsRisque.ItemsRisque[risque].objets,
            produits:risqueItem.itemsRisque.ItemsRisque[risque].produits,
            garanties:risqueItem.itemsRisque.ItemsRisque[risque].garanties,
            risque:risqueItem.itemsRisque.ItemsRisque[risque].risque,
            devis:risqueItem.itemsRisque.ItemsRisque[risque].devis.id,
          },
        }

        await this.$http.post(`portefeuilles/getTarif`,data).then((response)=>{
          this.getEtude.itemsRisque.ItemsRisque[risque].produits.tarif=[]
          this.getEtude.itemsRisque.ItemsRisque[risque].produits=response.data
          this.$store.commit("setItemsRisqueGlobal", {
            ItemsRisque: risqueItem.itemsRisque.ItemsRisque,
          });
        })

        this.$store.commit("setStepTarif", {
          step: {
            indexStep:2,
            active:'comparatif'
          },
        });

        this.showLoading=false

        // const response = await axios.post(`${url}api/portefeuilles/getTarif`,data);
        // this.loading=false
        // risqueChamps.itemsRisque.ItemsRisque[risque].produits.tarif=[]
        // risqueChamps.itemsRisque.ItemsRisque[risque].produits=response.data
        // this.$store.commit("setItemsRisqueGlobal", {
        //   ItemsRisque: risqueChamps.itemsRisque.ItemsRisque,
        // });

        // this.$store.commit("setStepTarif", {
        //   step: {
        //     indexStep:2,
        //     active:'comparatif'
        //   },
        // });

        // window.scrollTo({ top: 0, behavior: 'smooth' });

        } catch (e) {
          this.showLoading=true
          this.errors = e.response.data.errors;
        }
    },
    addEnfant(){
      this.enfantsArray.push({
        id:null,
        nom:null,
        prenom:null,
        date_naissance:null,
        state:null,
      })
    },
    removeEnfant(index){
      this.enfantsArray.splice(index, 1)
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss" scoped>
.title-custom-wizard{
    color: #4d25bc ;
    margin-bottom: 20px;
}
label{
    font-weight: 900;
}


@media only screen and (min-width: 700px) {
  #lg-popover .popover {
    width:600px;
    max-width:none; // Required for popovers wider than 276px (Bootstrap's max-width for popovers)
  }
}


</style>
